
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93q961D01iaEMeta } from "/src/pages/[id].vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
export default [
  {
    name: "id___en",
    path: "/en/:id()",
    component: () => import("/src/pages/[id].vue")
  },
  {
    name: "id___de___default",
    path: "/:id()",
    component: () => import("/src/pages/[id].vue")
  },
  {
    name: "id___de",
    path: "/de/:id()",
    component: () => import("/src/pages/[id].vue")
  },
  {
    name: "id___fr",
    path: "/fr/:id()",
    component: () => import("/src/pages/[id].vue")
  },
  {
    name: "id___pt",
    path: "/pt/:id()",
    component: () => import("/src/pages/[id].vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "index___de___default",
    path: "/",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/src/pages/index.vue")
  }
]